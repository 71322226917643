import React from 'react';
import { BrowserRouter, Routes, Route, Redirect } from 'react-router-dom'; 
import Layout from './Layout';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import './App.css';
const publicKey = 'pk_live_51LIEpcBmY72lVFZ7XaUDSFYcjMmKdm0VoTRJBooQcA7qzonUDz7rKiAmfQIIA93TIBme3tHUP9OWymmEvTdZS0Hi002kKVb9Kh';
const promise = loadStripe(publicKey);
function App() {
  return (    
    <div className="App">
      <Elements stripe={promise}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={ <Layout /> } />
          </Routes>
        </BrowserRouter>  
      </Elements>
      
    </div>
  );
}

export default App;
